import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

import ProjectRow from './project-row';

import { useClients } from '../../../../contexts/ClientsContext';
import { useProjects } from '../../../../contexts/ProjectsContext';

import { DefaultsProvider } from '../../../../contexts/DefaultsContext';

const mutedStyle = {
    fontWeight: 'normal',
    fontSize: '0.775rem',
    color: 'text.secondary',
};

const ProjectsTable = ({ client, userId, onAddClient, onUpdateClient, onRemoveClient, onCancelAdd }) => {
    const { allClients } = useClients();
    const { allProjects, addTemp, cancelTemp, create, update, remove } = useProjects();

    const [open, setOpen] = useState(false);
    const [clientName, setClientName] = useState(client?.name ? client?.name : '');
    const [isNameChanged, setIsNameChanged] = useState(false);
    const [isDuplicateName, setIsDuplicateName] = useState(false);

    const originalName = client?.name;
    const textFieldStyle = isDuplicateName ? { animation: 'shake 0.5s', color: 'red' } : {};

    useEffect(() => {
        setIsNameChanged(clientName !== originalName);
        setIsDuplicateName(allClients.some(c => c?._id !== client?._id && c?.name?.trim() === clientName?.trim()));
    }, [clientName, originalName, allClients, client._id]);

    const generateArrowsHeader = (open, clientId) => {
        if (clientId && clientId !== -1)
            return <>
                <TableCell component="th" scope="row" style={{ width: 15 }}>
                    <IconButton
                        aria-label="expand client row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </>;

        return;
    };

    const handleAddClientClicked = e => {
        e.preventDefault();
        onAddClient(clientName);
    };

    const handleUpdateClientClicked = e => {
        e.preventDefault();
        onUpdateClient(client._id, clientName);
    };

    const handleRemoveClientClicked = e => {
        e.preventDefault();
        onRemoveClient(client?._id);
    };

    const handleUndoClientChangesClicked = e => {
        e.preventDefault();

        setClientName(originalName ? originalName : '');

        setIsNameChanged(false);
    };

    const handleCancelAddClientClicked = e => {
        e.preventDefault();
        setClientName('');
        onCancelAdd();
    };

    const generateEndIcons = clientId => {
        if (clientId && clientId !== -1)
            return <>
                {
                    isNameChanged
                        ? <TableCell align="right" component="th" scope="row" style={{ width: 15 }}>
                            <IconButton
                                aria-label="uncdo client changes"
                                size="small"
                                color="warning"
                                onClick={handleUndoClientChangesClicked}
                                title="Undo changes mande to this client"
                            >
                                <UndoIcon />
                            </IconButton>
                        </TableCell>
                        : <TableCell align="right" colSpan={2} component="th" scope="row" style={{ width: 15 }}>
                            <IconButton
                                aria-label="remove client row"
                                size="small"
                                color="error"
                                onClick={handleRemoveClientClicked}
                                title="Permanently remove this client"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                }
                {
                    isNameChanged
                        ? <TableCell align="right" component="th" scope="row" style={{ width: 15 }}>
                            <IconButton
                                aria-label="update client row"
                                size="small"
                                color="success"
                                onClick={handleUpdateClientClicked}
                                disabled={!isNameChanged || isDuplicateName}
                                title="Accept all changes to the client"
                            >
                                <SaveIcon />
                            </IconButton>
                        </TableCell>
                        : null
                }
            </>;

        return <>
            <TableCell align="right" component="td" scope="row" style={{ width: 15 }}>
                <IconButton
                    aria-label="cancel add client row"
                    size="small"
                    color="warning"
                    onClick={handleCancelAddClientClicked}
                    title="Cancel creating this client"
                >
                    <ClearIcon />
                </IconButton>
            </TableCell>
            <TableCell align="right" component="td" scope="row" style={{ width: 15 }}>
                <IconButton
                    aria-label="add client row"
                    size="small"
                    color="success"
                    onClick={handleAddClientClicked}
                    disabled={isDuplicateName || !isNameChanged}
                    title="Add this client"
                >
                    <SaveIcon />
                </IconButton>
            </TableCell>
        </>;
    };

    const handleClientTextboxChange = e => {
        setClientName(e.target.value);
    };

    const handleAddProject = async ({ name, type, rate }) => {
        await create({ clientId: client._id, name: name, type: type, rate: rate, userId });
    };

    const handleUpdateProject = async ({ projectId, name, type, rate }) => {
        await update({ projectId, name: name, type: type, rate: rate, userId });
    };

    const handleRemoveProject = async projectId => {
        await remove(projectId);
    };

    const generateClientControl = clientId => {
        if (clientId && clientId === -1)
            return <>
                <TableCell component="td" scope="row" colSpan={2}>
                    <TextField
                        label={isDuplicateName ? "Client Name already exists" : "New Client Name"}
                        value={clientName}
                        onChange={handleClientTextboxChange}
                        size="small"
                        fullWidth
                        style={textFieldStyle}
                        color={isDuplicateName ? "warning" : "primary"}
                        autoFocus
                        sx={{ maxWidth: 800 }}
                    />
                </TableCell>
            </>;

        return <>
            <TableCell component="td" scope="row">
                <TextField
                    label={isDuplicateName ? "Client Name already exists" : "Client Name"}
                    value={clientName}
                    onChange={handleClientTextboxChange}
                    size="small"
                    fullWidth
                    style={textFieldStyle}
                    color={isDuplicateName ? "warning" : "primary"}
                    sx={{ maxWidth: 800 }}
                />
            </TableCell>
        </>;
    };

    return <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            {generateArrowsHeader(open, client?._id)}
            {generateClientControl(client?._id)}
            {generateEndIcons(client?._id)}
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1, mb: 1 }}>

                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" gutterBottom component="div" style={{ paddingLeft: 15 }}>
                                    Projects
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    aria-label="add project row"
                                    size="small"
                                    color="primary"
                                    onClick={() => addTemp(client._id)}
                                    disabled={allProjects?.some(client => client._id === -1)}
                                    style={{ marginRight: 15 }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        {allProjects.length > 0 ? (
                            <Table size="small" aria-label="purchases">
                                <TableBody sx={{ '& > :last-child > *': { borderBottom: 'none' } }}>
                                    <DefaultsProvider>
                                        {
                                            allProjects.map(
                                                project =>
                                                    <ProjectRow
                                                        key={project._id}
                                                        project={project}
                                                        allProjects={allProjects}
                                                        onAddProject={handleAddProject}
                                                        onUpdateProject={handleUpdateProject}
                                                        onRemoveProject={handleRemoveProject}
                                                        onCancelAdd={cancelTemp}
                                                    />)
                                        }
                                    </DefaultsProvider>
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="body1" style={{ marginLeft: 16 }} sx={{ ...mutedStyle, mb: 2, mt: 1 }}>
                                No projects available. Add some now.
                            </Typography>
                        )}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>;
};

ProjectsTable.propTypes = {
    client: PropTypes.shape({
        _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
    }),
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onAddClient: PropTypes.func,
    onUpdateClient: PropTypes.func,
    onRemoveClient: PropTypes.func,
    onCancelAdd: PropTypes.func,
};


export default ProjectsTable;
