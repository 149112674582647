// api url (where your server is hosted at)
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const DEFAULT_CURRENCY = 'USD';
const DEFAULT_CURRENCIES = [
	{ code: 'USD', name: 'United States Dollar', symbol: '$' },
	{ code: 'EUR', name: 'Euro', symbol: '€' },
	{ code: 'JPY', name: 'Japanese Yen', symbol: '¥' },
	{ code: 'GBP', name: 'British Pound', symbol: '£' },
	{ code: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
	{ code: 'CAD', name: 'Canadian Dollar', symbol: 'C$' },
	{ code: 'CHF', name: 'Swiss Franc', symbol: 'CHF' },
	{ code: 'CNY', name: 'Chinese Yuan', symbol: '¥' },
	{ code: 'SEK', name: 'Swedish Krona', symbol: 'kr' },
	{ code: 'NZD', name: 'New Zealand Dollar', symbol: 'NZ$' },
	{ code: 'MXN', name: 'Mexican Peso', symbol: '$' },
	{ code: 'SGD', name: 'Singapore Dollar', symbol: 'S$' },
	{ code: 'HKD', name: 'Hong Kong Dollar', symbol: 'HK$' },
	{ code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' },
	{ code: 'KRW', name: 'South Korean Won', symbol: '₩' },
	{ code: 'TRY', name: 'Turkish Lira', symbol: '₺' },
	{ code: 'RUB', name: 'Russian Ruble', symbol: '₽' },
	{ code: 'INR', name: 'Indian Rupee', symbol: '₹' },
	{ code: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
	{ code: 'ZAR', name: 'South African Rand', symbol: 'R' },
	{ code: 'DKK', name: 'Danish Krone', symbol: 'kr' },
	{ code: 'PLN', name: 'Polish Zloty', symbol: 'zł' },
	{ code: 'TWD', name: 'New Taiwan Dollar', symbol: 'NT$' },
	{ code: 'THB', name: 'Thai Baht', symbol: '฿' },
	{ code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' },
	{ code: 'HUF', name: 'Hungarian Forint', symbol: 'Ft' },
	{ code: 'CZK', name: 'Czech Koruna', symbol: 'Kč' },
	{ code: 'ILS', name: 'Israeli New Shekel', symbol: '₪' },
	{ code: 'CLP', name: 'Chilean Peso', symbol: '$' },
	{ code: 'PHP', name: 'Philippine Peso', symbol: '₱' },
	{ code: 'AED', name: 'United Arab Emirates Dirham', symbol: 'د.إ' },
	{ code: 'COP', name: 'Colombian Peso', symbol: '$' },
	{ code: 'SAR', name: 'Saudi Riyal', symbol: '﷼' },
	{ code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' },
	{ code: 'RON', name: 'Romanian Leu', symbol: 'lei' },
	{ code: 'ARS', name: 'Argentine Peso', symbol: '$' },
	{ code: 'BGN', name: 'Bulgarian Lev', symbol: 'лв' },
	{ code: 'EGP', name: 'Egyptian Pound', symbol: '£' },
	{ code: 'VND', name: 'Vietnamese Dong', symbol: '₫' },
	{ code: 'PKR', name: 'Pakistani Rupee', symbol: '₨' },
	{ code: 'BDT', name: 'Bangladeshi Taka', symbol: '৳' },
	{ code: 'UAH', name: 'Ukrainian Hryvnia', symbol: '₴' },
	{ code: 'KES', name: 'Kenyan Shilling', symbol: 'Sh' },
];

const DEFAULT_TIMEZONE = 'America/New_York';

export {
	BACKEND_URL,

	DEFAULT_CURRENCY,
	DEFAULT_CURRENCIES,

	DEFAULT_TIMEZONE
};
