import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const LocalizedDate = ({ date }) => {
    const formattedDate = dayjs(date).format('L');

    return <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <span>{formattedDate}</span>
        </LocalizationProvider>
    </>;
};

export default LocalizedDate;