import { useState } from 'react';

import { ClientsProvider } from '../../../../contexts/ClientsContext';
import { ExpensesProvider } from '../../../../contexts/ExpensesContext';
import { ProjectsProvider } from '../../../../contexts/ProjectsContext';
import { IncomesProvider } from '../../../../contexts/IncomesContext';
import { DefaultsProvider } from '../../../../contexts/DefaultsContext';

import {
    Box,
    Container,
    Tabs,
    Tab
} from '@mui/material';

import Expenses from './expenses';
import Incomes from './income';
// import Summary from './Summary.jsx';

const Main = () => {
    const [value, setValue] = useState(0);

    const handleChange = (_event, newValue) => {
        setValue(parseInt(newValue));
    };

    const generateTabContent = tabValue => {
        switch (tabValue) {
            case 3:
                return null;
            case 2:
                return null;
            case 1:
                return <>
                    <ClientsProvider>
                        <ProjectsProvider>
                            <IncomesProvider>
                                <Incomes />
                            </IncomesProvider>
                        </ProjectsProvider>
                    </ClientsProvider>
                </>;
            case 0:
            default:
                return <>
                    <ExpensesProvider>
                        <Expenses />
                    </ExpensesProvider>
                </>;
        };
    }

    return <>
        <Container maxWidth="xxl">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={{ paddingTop: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Tabs
                        value={value.toString()}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs"
                        centered
                    >
                        <Tab label="Expenses" value="0" />
                        <Tab label="Income" value="1" />
                        {/* <Tab label="Taxes" value="2" />
                        <Tab label="Profit-Loss" value="3" /> */}
                    </Tabs>
                </Box>
                <Box sx={{ paddingLeft: 5, paddingRight: 5, width: "100%", flexGrow: 1 }}>
                    <DefaultsProvider>
                        {generateTabContent(value)}
                    </DefaultsProvider>
                </Box>
            </Box>
        </Container>
    </>;
};

export default Main;
