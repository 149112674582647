import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { DEFAULT_CURRENCY, DEFAULT_CURRENCIES } from '../../../constants';

const Currencies = ({ name, selectedCurrencySymbol, onChange, disabled }) => {
    const defaultCurrency = selectedCurrencySymbol
        ? DEFAULT_CURRENCIES.find(currency => currency.code === selectedCurrencySymbol)
        : DEFAULT_CURRENCIES.find(currency => currency.code === DEFAULT_CURRENCY);

    const handleAutocompleteChange = (newValue) => {
        // Create a synthetic event object
        onChange({
            target: {
                name,
                value: newValue ? newValue.code : ''
            }
        });
    };

    return <>
        <Autocomplete
            options={DEFAULT_CURRENCIES}
            getOptionLabel={(option) => `${option.code} - ${option.name} - (${option.symbol})`}
            value={defaultCurrency}
            onChange={(_event, newValue) => handleAutocompleteChange(newValue)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField {...params} label="Currency" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.code === value.code}
        />
    </>;
};

// Define PropTypes
Currencies.propTypes = {
    selectedCurrencySymbol: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired
};

// Set default props
Currencies.defaultProps = {
    selectedCurrencySymbol: DEFAULT_CURRENCY,
    disabled: false
};

export default Currencies;