import {
    Box,
    Grid,
    Paper,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';

import { useAuth } from '../../../../../contexts/AuthContext';
import { useClients } from '../../../../../contexts/ClientsContext';
import { useIncomes } from '../../../../../contexts/IncomesContext';
import { useProjects } from '../../../../../contexts/ProjectsContext';

import ClientHeader from './client-header';

import UpsertModal from './modals/upsert';

const mutedStyle = {
    fontWeight: 'normal',
    color: 'text.secondary',
};

const Income = () => {
    const { userId } = useAuth();
    const { create } = useIncomes();
    const { allClients, isClientsLoading, errorClients } = useClients();
    const { allProjects, isProjectsLoading } = useProjects();

    const handleAddNewIncome = async ({ clientId, projectId, amount, invoicedDate, invoiceSettledDate, hours, minutes }) => {
        await create({ clientId, projectId, amount, invoicedDate, invoiceSettledDate, hours, minutes, userId });
    };

    const generateLoadingIndicator = () => {
        return <>
            <TableRow>
                <TableCell align="center" sx={mutedStyle}>Loading Clients...</TableCell>
            </TableRow>
        </>;
    };

    const generateErrorIndicator = error => {
        return <>
            <TableRow>
                <TableCell align="center" sx={{ ...mutedStyle, color: 'red' }}>Error: {error.message}</TableCell>
            </TableRow>
        </>;
    };

    const generateClientRows = () => {
        if (isClientsLoading) {
            return <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="loading incomes table">
                    <TableBody>
                        {generateLoadingIndicator()}
                    </TableBody>
                </Table>
            </TableContainer>;
        }

        if (errorClients) {
            return <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="error incomes table">
                    <TableBody>
                        {generateErrorIndicator(errorClients)}
                    </TableBody>
                </Table>
            </TableContainer>;
        }

        if (allClients && allClients?.length > 0) {
            return allClients.map((client) => <TableContainer key={client?._id + '-client-header'} component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="collapsible incomes table">
                    <TableBody>
                        <ClientHeader client={client} clients={allClients} />
                    </TableBody>
                </Table>
            </TableContainer>);
        }

        return <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible incomes table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" sx={mutedStyle}>No clients set up yet. Add some now.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>;
    };

    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid container justifyContent="right" sx={{ m: 2 }}>
                <Grid item>
                    <UpsertModal
                        verb="Add"

                        clients={allClients}
                        projects={allProjects}

                        onAdd={handleAddNewIncome}

                        disabled={isClientsLoading || isProjectsLoading}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {generateClientRows()}
                </Grid>
            </Grid>
        </Box>
    </>;
};

export default Income;