import {
    createContext,
    useContext
} from 'react';

import {
    SnackbarProvider as NotistackProvider,
    useSnackbar as useNotistackSnackbar
} from 'notistack';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const SNACKBAR_SEVERITIES = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success',
};

const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

const InnerSnackbarProvider = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

    const handleClose = (key) => () => {
        closeSnackbar(key);
    };

    const generateKey = (messageText) => `${messageText}-${new Date().getTime()}`;

    const showSnackbar = (messages, severity = SNACKBAR_SEVERITIES.INFO, duration = 5000) => {
        if (!Array.isArray(messages)) {
            messages = [{ text: messages, severity, duration }];
        }

        messages.forEach((message) => {
            const messageText = typeof message === 'string' ? message : message.text;
            const messageSeverity = message.severity || severity;
            const messageDuration = message.duration || duration;
            const key = generateKey(messageText);

                enqueueSnackbar(messageText, {
                    variant: messageSeverity,
                    autoHideDuration: messageDuration,
                    key,
                    action: (
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose(key)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )
                });
            // }
        });
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar, SNACKBAR_SEVERITIES }}>
            {children}
        </SnackbarContext.Provider>
    );
};

const SnackbarProvider = ({ children }) => {
    return (
        <NotistackProvider
            maxSnack={10}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            hideIconVariant>
            <InnerSnackbarProvider>
                {children}
            </InnerSnackbarProvider>
        </NotistackProvider>
    );
};

export default SnackbarProvider;
