import Container from '@mui/material/Container';

import ClientsProjectsBox from './clients-projects-box';
import DefaultsBox from './defaults-box';
import SignInBox from './sign-in-box';

import { AccountProvider } from '../../contexts/AccountContext';
import { ClientsProvider } from '../../contexts/ClientsContext';
import { ProjectsProvider } from '../../contexts/ProjectsContext';
import { DefaultsProvider } from '../../contexts/DefaultsContext';

import { useAuth } from '../../contexts/AuthContext';

const Account = () => {
    const { userId } = useAuth();

    return <>
        <Container maxWidth="lg">
            <AccountProvider>
                <SignInBox userId={userId} />
            </AccountProvider>
            <DefaultsProvider>
                <DefaultsBox userId={userId} />
            </DefaultsProvider>
            <ClientsProvider>
                <ProjectsProvider>
                    <ClientsProjectsBox userId={userId} />
                </ProjectsProvider>
            </ClientsProvider>
        </Container>
    </>;
};

export default Account;
