import { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@mui/material';

import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';

import { useAuth } from '../../../../../contexts/AuthContext';
import { useSnackbar } from '../../../../../contexts/SnackbarContext';

const textFieldProps = {
    margin: "dense",
    type: "text",
    fullWidth: true,
    required: true
};

const SignIn = ({ toggleForm }) => {
    const { signIn } = useAuth();
    const { showSnackbar, SNACKBAR_SEVERITIES } = useSnackbar();

    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({ email: false, password: false });
    const [showPassword, setShowPassword] = useState(false);

    const isValidEmail = email => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateForm = () => {
        const errors = {};
        errors.email = !formData.email || !isValidEmail(formData.email);
        errors.password = !formData.password;
        setFieldErrors(errors);
        return errors.email || errors.password;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            showSnackbar('Please enter valid email and password', SNACKBAR_SEVERITIES.ERROR);
            return;
        }

        setLoading(true);

        try {
            const response = await signIn(formData);
            if (response?.data?.message) {
                showSnackbar(response?.data?.message);
            }
            setLoading(false);
        } catch (err) {
            showSnackbar('Sign in failed. Please try again.', SNACKBAR_SEVERITIES.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setFieldErrors(prev => ({ ...prev, [name]: false }));
    };

    const handleEnterPressed = e => {
        if (e.key === 'Enter' && formData.email && formData.password) {
            handleSubmit();
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return <>
        <Box textAlign="center">
            <Typography variant="h5" sx={{ mb: 2 }}>Sign Into Account</Typography>
        </Box>
        <TextField
            data-testid="email-input"
            label='Email'
            name='email'
            onChange={handleChange}
            onKeyPress={handleEnterPressed}
            value={formData.email}
            disabled={loading}
            autoFocus
            error={fieldErrors.email}
            {...textFieldProps}
        />
        <TextField
            data-testid="password-input"
            label='Password'
            name='password'
            onChange={handleChange}
            onKeyPress={handleEnterPressed}
            value={formData.password}
            disabled={loading}
            error={fieldErrors.password}
            helperText={fieldErrors.password && 'Password is required'}
            {...textFieldProps}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        <Button
            data-testid="sign-in-button"
            onClick={handleSubmit}
            disabled={!formData.email || !formData.password || loading}
            variant="contained"
            size="large"
            fullWidth
            sx={{ mt: 2 }}
            startIcon={loading && <CircularProgress size={15} />}
        >
            Sign In
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
            <Typography variant="body2">
                No account yet?&nbsp;
                <Link href="#" onClick={toggleForm} style={{ cursor: 'pointer' }}>
                    Register
                </Link>
            </Typography>
        </Box>
    </>;
};

SignIn.propTypes = {
    toggleForm: PropTypes.func.isRequired,
};

export default SignIn;
