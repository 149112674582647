import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';

const TimeChip = ({ hours, minutes, type, onClick, disabled, sx, color  }) => {
    const handleClick = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    return <>
        <Chip
            label={`${hours?.toString()?.padStart(2, '0')}:${minutes?.toString()?.padStart(2, '0')}`}
            variant={type}
            color={color}
            sx={{ ...sx }}
            avatar={<AccessTime />}
            onClick={handleClick}
            disabled={disabled}
        />
    </>;
};

TimeChip.propTypes = {
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['default', 'outlined', 'filled']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    sx: PropTypes.object,
};

TimeChip.defaultProps = {
    type: 'default',
    disabled: false,
    sx: {}
};

export default TimeChip;
