import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const IncomeFrequency = ({ frequency, onChange }) => {
    const incomeFrequencies = [
        { code: "ad-hoc", description: "Ad-Hoc" },
        { code: "bi-weekly", description: "Bi-Weekly" },
        { code: "monthly", description: "Monthly" }
    ];

    // Function to find the type object that matches the given code
    const findTypeByCode = (code) => {
        if (!code)
            return null; // Return null or a default value if the code is empty
        return incomeFrequencies.find(type => type.code === code);
    };

    // Initialize selectedFrequency with the type object that matches the frequency prop
    const [selectedFrequency, setSelectedFrequency] = useState(findTypeByCode(frequency));

    // Update selectedFrequency when the frequency prop changes
    useEffect(() => {
        setSelectedFrequency(findTypeByCode(frequency));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency]);

    return <>
        <Autocomplete
            options={incomeFrequencies}
            getOptionLabel={(option) => option ? option.description : ''}
            value={selectedFrequency}
            size="small"
            onChange={(_event, newValue) => {
                setSelectedFrequency(newValue);
                onChange(newValue ? newValue.code : '');
            }}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderInput={(params) => (
                <TextField {...params} label="Income Frequency" variant="outlined" />
            )}
        />
    </>;
};

export default IncomeFrequency;
