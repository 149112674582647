import PropTypes from 'prop-types';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

import dayjs from 'dayjs';

import UpsertModal from '../modals/upsert';

import MoneyChip from '../../../../../custom-controls/chips/money';

const ExpensesTable = ({ expenses, onUpdate, onRemove }) => {
    const getMonthNames = () => {
        return Array.from({ length: 12 }, (_, i) => dayjs(new Date(new Date().getFullYear(), i, 1)).format('MMMM'));
    };

    const monthNames = getMonthNames();

    const isDateInMonth = (date, month) => {
        return dayjs(date).month() === month;
    };

    const generateExpenseYearlyTotal = expenses => {
        const totalExpense = expenses.reduce((accumulator, expense) => {
            if (isNaN(expense?.amount))
                return accumulator;

            return accumulator + Number(expense?.amount);
        }, 0);

        return <TableCell key={`total-expense-for-the-year-${totalExpense}-${Math.random()}`} align="center">
            <MoneyChip
                amount={totalExpense}
                type={"default"}
                sx={{ m: 1 }}
            />
        </TableCell>;
    };

    const generateAmountUpdateChip = ({ expense }) => {
        return <UpsertModal
            key={`upsert-expense-update-${expense?._id}`}
            verb={'Update'}
            expenseId={expense?._id}
            type={expense?.type}
            description={expense?.description}
            amount={expense?.amount}
            date={expense?.date}
            onUpdate={onUpdate}
        />;
    };

    const generateExpenseRow = (expenses, description) => {
        const currentYear = dayjs().format('YYYY'); //  Todo, replace this with a variable at some point

        const yearlyExpenses = monthNames.map(month => {

            const expenseForMonth = expenses
                ?.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);

                    return dateA - dateB; // sorts dates from earliest to latest
                })
                ?.filter(expense => isDateInMonth(expense?.date, month?.index))
                ?.filter(expense => {
                    const incomeYear = dayjs(expense?.date).format('YYYY');
                    return incomeYear === currentYear;
                })
                ?.map(expense => generateAmountUpdateChip({ expense, type: 'outlined' }))

            if (typeof expenseForMonth === 'undefined' || expenseForMonth?.length <= 0)
                return <TableCell key={`empty-${month.name}-${description}`}></TableCell>;

            return <TableCell key={`expense-for-the-month-${description}-${month.name}`} align="center">
                {expenseForMonth}
            </TableCell>;
        });

        return yearlyExpenses;
    };

    const getArrayOfExpenses = expenses => {
        return expenses.reduce((acc, obj) => {
            const description = obj?.description;

            // Check if the description is unique
            if (description && !acc.set.has(description)) {
                acc.set.add(description);
                acc.array.push(description);
            }

            return acc;
        }, { set: new Set(), array: [] }).array; // Initialize the accumulator with a Set and an array
    };

    const generateExpenseRows = () => {
        if (!expenses || expenses?.length <= 0)
            return <TableRow>
                <TableCell colSpan={13} align="center">&nbsp;</TableCell>
            </TableRow>;

        const descriptions = getArrayOfExpenses(expenses);

        return descriptions
            .sort((a, b) => {
                const descriptionA = a.toUpperCase();
                const descriptionB = b.toUpperCase();

                if (descriptionA < descriptionB) {
                    return -1;
                }
                if (descriptionA > descriptionB) {
                    return 1;
                }

                // names must be equal
                return 0;
            })
            .map(description => {
                return <TableRow key={`expense-row-${description}`}>
                    <TableCell align="center">{description}</TableCell>
                    {generateExpenseRow(expenses?.filter(expense => expense?.description === description), description)}
                    {generateExpenseYearlyTotal(expenses?.filter(expense => expense?.description === description))}
                </TableRow>;
            });
    };

    const generateMonthlyTotal = month => {
        const totalsForMonth = expenses
            ?.filter(expense => isDateInMonth(expense?.date, month?.index))
            ?.reduce((accumulator, expense) => {
                if (isNaN(expense?.amount))
                    return accumulator;

                return accumulator + Number(expense?.amount);
            }, 0);

        return <TableCell key={`total-${month?.name}`} align="center">
            <MoneyChip
                amount={totalsForMonth}
                type={"default"}
                sx={{ m: 1 }}
            />
        </TableCell>;
    };

    const generateCompleteYearlyTotal = () => {
        const totalsForYear = expenses
            ?.reduce((accumulator, expense) => {
                if (isNaN(expense?.amount))
                    return accumulator;

                return accumulator + Number(expense?.amount);
            }, 0);

        return <TableCell align="center">
            <MoneyChip
                amount={totalsForYear}
                type={"default"}
                sx={{ m: 1 }}
                color={"primary"}
            />
        </TableCell>;
    };

    const generateMonthlyTotalsRow = () => {
        if (!expenses || expenses?.length <= 0)
            return <TableRow>
                <TableCell colSpan={13} align="center">&nbsp;</TableCell>
            </TableRow>;

        return <TableRow>
            <TableCell align="center"><b>Totals</b></TableCell>
            {monthNames.map(month => generateMonthlyTotal(month))}
            {generateCompleteYearlyTotal()}
        </TableRow>;
    };

    const generateMonthHeaders = () => {
        return monthNames.map(m => (
            <TableCell key={m.name + '-expense-header'} align="center"><b>{m.name}</b></TableCell>
        ));
    };

    return <>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table stickyHeader aria-label="expenses table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><b>Expense</b></TableCell>
                        {generateMonthHeaders()}
                        <TableCell align="center"><b>Total</b></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {generateExpenseRows()}
                    {generateMonthlyTotalsRow()}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
};

ExpensesTable.propTypes = {
    expenses: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            type: PropTypes.string,
            description: PropTypes.string,
            amount: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            date: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date)
            ])
        })
    ),
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
};

export default ExpensesTable;
