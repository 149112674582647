import { useState, useEffect } from 'react';
import { Box, Container, Fade } from '@mui/material';

import SignIn from './sign-in';
import Register from './register';

const fadeInOutDuration = 500;

const Anonymous = () => {
    const [isRegisterMode, setIsRegisterMode] = useState(false);
    const [showTransition, setShowTransition] = useState(true);

    useEffect(() => {
        if (!showTransition) {
            const timer = setTimeout(() => {
                setIsRegisterMode(!isRegisterMode);
                setShowTransition(true);
            }, fadeInOutDuration);

            return () => clearTimeout(timer);
        }
    }, [isRegisterMode, showTransition]);

    const toggleForm = () => {
        setShowTransition(false);
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                <Fade in={showTransition} timeout={fadeInOutDuration}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            padding: 3,
                            borderRadius: 1,
                            boxShadow: 3,
                            maxWidth: 600,
                        }}
                    >
                        {isRegisterMode ? <Register toggleForm={toggleForm} /> : <SignIn toggleForm={toggleForm} />}
                    </Box>
                </Fade>
            </Box>
        </Container>
    );
};

export default Anonymous;
