import {
	createContext,
	useCallback,
	useContext,
	useEffect
} from 'react';

import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [userId, setUserId] = useLocalStorage('userId');
	const [token, setToken] = useLocalStorage('token');

	const decodeJWT = token => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
		return JSON.parse(jsonPayload);
	};
	

	const register = useCallback(async (formData = {}) => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const defaultsData = { currency: 'USD', timezone: userTimezone };

		const response = await axios
			.post('/auth/register', { ...formData, ...defaultsData })
			.catch((error) => {
				throw error;
			});

		const userId = decodeJWT(response?.data?.token)?.uid;

		setUserId(userId);
		setToken(response?.data?.token);
	}, [setToken, setUserId]);

	const signIn = useCallback(async (formData = {}) => {
		const response = await axios
			.post('/auth/sign-in', formData)
			.catch((error) => {
				throw error;
			});

		const userId = decodeJWT(response?.data?.token)?.uid;

		setUserId(userId);
		setToken(response?.data?.token);
	}, [setToken, setUserId]);

	const signInWithToken = useCallback(async () => {
		const response = await axios
			.get('/auth/sign-in', {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
			.catch((error) => {
				throw error;
			});

		const userId = decodeJWT(response?.data?.token)?.uid;

		setUserId(userId);
		setToken(response?.data?.token);
	}, [setToken, setUserId, token]);

	const signOut = () => {
		setUserId(null);
		setToken(null);
	};

	// This side effect runs only if we have a token, but no userId or signed-in boolean.
	// This "if" statement is "true" only when refreshed, or re-opened the browser,
	// if true, it will then ask the backend for the account information (and will get them if the token hasn't expired)
	useEffect(() => {
		if (!userId && token)
			signInWithToken();
	}, [userId, token, signInWithToken]);

	return <>
		<AuthContext.Provider
			value={{
				userId,
				token,

				register,
				signIn,
				signOut,
			}}>
			{children}
		</AuthContext.Provider>
	</>;
};
