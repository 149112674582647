import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';

const LocalizedTimezones = ({ name, selectedTimezone, onChange, disabled }) => {
    // Prepare timezone data
    const timezones = moment.tz.names().map(tz => ({
        label: tz,
        offset: moment.tz(tz).format('Z')
    }));

    // Get user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Find the timezone object that matches the user's timezone
    const defaultTimezone = !selectedTimezone
        ? timezones.find(tz => tz.label === userTimezone)
        : timezones.find(tz => tz.label === selectedTimezone);

    const handleAutocompleteChange = (newValue) => {
        // Create a synthetic event object
        onChange({
            target: {
                name,
                value: newValue ? newValue.label : ''
            }
        });
    };

    return (
        <Autocomplete
            options={timezones}
            getOptionLabel={(option) => `${option.label.replace(/_/g, ' ')} (UTC${option.offset})`}
            value={defaultTimezone}
            onChange={(_event, newValue) => handleAutocompleteChange(newValue)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField {...params} label="Timezone" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.code === value.code}
        />
    );
};

export default LocalizedTimezones;
