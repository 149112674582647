import PropTypes from 'prop-types';

import {
    Grid,
    Typography
} from '@mui/material';

import { useAuth } from '../../../../../../contexts/AuthContext';
import { useIncomes } from '../../../../../../contexts/IncomesContext';

import IncomeDataGrid from '../data-grid';

const mutedStyle = {
    fontWeight: 'normal',
    color: 'text.secondary',
};

const ProjectHeader = ({ project, clients, projects }) => {
    const { userId } = useAuth();
    const { allIncomes, isIncomesLoading, update, remove } = useIncomes();

    const handleUpdateIncome = async ({ incomeId, clientId, projectId, amount, invoicedDate, invoiceSettledDate, hours, minutes }) => {
        await update({ incomeId, clientId, projectId, amount, invoicedDate, invoiceSettledDate, hours, minutes, userId });
    };

    const handleRemoveIncome = async ({ incomeId }) => {
        await remove(incomeId);
    };

    const generateIncomeContent = projectId => {
        if (isIncomesLoading)
            return <Typography variant="body1" style={{ marginLeft: 16 }} sx={{ ...mutedStyle, mb: 2, mt: 1 }}>
                Loading Incomes
            </Typography>;

        return <>
            <IncomeDataGrid
                incomes={allIncomes?.filter(income => income?.projectId === projectId)}
                clients={clients}
                projects={projects}
                onUpdate={handleUpdateIncome}
                onRemove={handleRemoveIncome}
            />
        </>;
    };

    const capitalizeWords = inputString => {
        return inputString
            .split(/\s+/)
            .map(segment =>
                segment
                    .split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join('-')
            )
            .join(' ');
    };

    return <>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
                <Typography variant="subtitle1" style={{ paddingLeft: 15 }}>
                    {project?.name} @ ${project?.rate}/hour, {capitalizeWords(project?.type)}
                </Typography>
            </Grid>
        </Grid>

        {generateIncomeContent(project?._id)}
    </>;
};

ProjectHeader.propTypes = {
    project: PropTypes.object.isRequired,
    clients: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
};

export default ProjectHeader;