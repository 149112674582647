import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import ProjectsTable from './projects-table';

import { useClients } from '../../../contexts/ClientsContext';

const mutedStyle = {
    fontWeight: 'normal',
    color: 'text.secondary',
};

const ClientsProjectsBox = ({ userId }) => {
    const { allClients, isClientsLoading, errorClients, addTemp, cancelTemp, create, update, remove } = useClients();

    const handleAddClient = async newClientName => {
        await create({ name: newClientName, userId });
    };

    const handleUpdateClient = async (clientId, newClientName) => {
        await update({ clientId, name: newClientName });
    };

    const handleRemoveClient = async clientId => {
        await remove(clientId)
    };

    const generateClientRows = () => {
        if (isClientsLoading) {
            return <TableRow>
                <TableCell align="center" sx={mutedStyle}>Loading...</TableCell>
            </TableRow>;
        }

        if (errorClients) {
            return <TableRow>
                <TableCell align="center" sx={{ ...mutedStyle, color: 'red' }}>Error: {errorClients.message}</TableCell>
            </TableRow>;
        }

        if (allClients?.length > 0) {
            return allClients.map((client) => (
                <ProjectsTable
                    key={client._id}
                    client={client}
                    userId={userId}
                    onAddClient={handleAddClient}
                    onUpdateClient={handleUpdateClient}
                    onRemoveClient={handleRemoveClient}
                    onCancelAdd={cancelTemp}
                />
            ));
        }

        return <TableRow>
            <TableCell align="center" sx={mutedStyle}>No clients available. Add some now.</TableCell>
        </TableRow>;
    };

    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 6 }}
        >
            <Grid container item xs={6} md={12} spacing={2} alignItems="center">
                <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography gutterBottom variant="h5" component="div">
                            Clients &amp; Projects
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: 10 }}>
                        <Fab
                            aria-label="add client row"
                            size="small"
                            color="primary"
                            onClick={addTemp}
                            disabled={allClients?.some(client => client._id === -1)}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible clients table">
                            <TableBody>
                                {generateClientRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    </>;
};

ClientsProjectsBox.propTypes = {
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ClientsProjectsBox;