import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialValue = null) => {
    const readValue = () => {
        if (typeof window === "undefined") {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState(readValue);

    useEffect(() => {
        if (typeof window === "undefined") {
            console.warn(`Tried setting localStorage key “${key}” even though environment is not a browser`);
            return;
        }

        if (storedValue === null) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedValue]);

    return [storedValue, setStoredValue];
};

export default useLocalStorage;
