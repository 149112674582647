import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

const CustomDatePicker = ({ label, value, onChange }) => {
    const handleDateChange = (newDate) => {
        if (newDate && typeof newDate === 'object' && newDate.isValid()) {
            onChange(newDate.toDate().toISOString());
        }
    };

    return <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={value}
                onChange={handleDateChange}
                slots={{
                    input: {
                        error: false, // Set based on your validation logic
                        helperText: '', // Set based on your validation logic
                        InputLabelProps: { shrink: true },
                        placeholder: label,
                        label: label,
                        renderInput: (params) => <TextField {...params} />
                    }
                }}
            />
        </LocalizationProvider>
    </>;
};

CustomDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onChange: PropTypes.func.isRequired
};

export default CustomDatePicker;
