import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

const IncomesContext = createContext();

export const useIncomes = () => {
    return useContext(IncomesContext);
};

export const IncomesProvider = ({ children }) => {
    const [allIncomes, setAllIncomes] = useState([]);
    const [isIncomesLoading, setIsIncomesLoading] = useState(false);
    const [errorIncomes, setErrorIncomes] = useState(null);
    
	const [userId] = useLocalStorage('userId');

    const getAll = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/incomes/user/${userId}`);
            setAllIncomes(response?.data?.data)
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (userId && userId !== null) {
            const fetchIncomes = async () => {
                setIsIncomesLoading(true);
                try {
                    const fetchedIncomes = await getAll(userId);
                    if (isMounted) {
                        setAllIncomes(fetchedIncomes);
                    }
                } catch (err) {
                    if (isMounted) {
                        setErrorIncomes(err);
                    }
                } finally {
                    if (isMounted) {
                        setIsIncomesLoading(false);
                    }
                }
            };

            fetchIncomes();
        }

        return () => {
            isMounted = false;
        };
    }, [getAll, userId]);

    const create = useCallback(async (income) => {
        try {
            const response = await axios.put(`/incomes`, income);
            const newIncomeId = response?.data?.id;
            setAllIncomes(prevVal => [...prevVal, { _id: newIncomeId, ...income }]);
            return newIncomeId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const update = useCallback(async (income) => {
        try {
            const response = await axios.post(`/incomes`, income);
            const existingIncomeId = response?.data?.id;
            setAllIncomes(prevVal => [...prevVal.filter(income => income._id !== existingIncomeId), { _id: existingIncomeId, ...income }]);
            return existingIncomeId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const remove = useCallback(async (incomeId) => {
        try {
            await axios.delete(`/incomes/${incomeId}`);
            setAllIncomes(prevVal => [...prevVal.filter(income => income._id !== incomeId)]);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <IncomesContext.Provider
            value={{
                allIncomes,
                isIncomesLoading,
                errorIncomes,

                getAll,
                create,
                update,
                remove
            }}>
            {children}
        </IncomesContext.Provider>
    </>;
};
