import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

const ExpensesContext = createContext();

export const useExpenses = () => {
    return useContext(ExpensesContext);
};

export const ExpensesProvider = ({ children }) => {
    const [allExpenses, setAllExpenses] = useState([]);
    const [isExpensesLoading, setIsExpensesLoading] = useState(false);
    const [errorExpenses, setErrorExpenses] = useState(null);
    
	const [userId] = useLocalStorage('userId');

    const getAll = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/expenses/user/${userId}`);
            setAllExpenses(response?.data?.data)
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (userId && userId !== null) {
            const fetchExpenses = async () => {
                setIsExpensesLoading(true);
                try {
                    const fetchedExpenses = await getAll(userId);
                    if (isMounted) {
                        setAllExpenses(fetchedExpenses);
                    }
                } catch (err) {
                    if (isMounted) {
                        setErrorExpenses(err);
                    }
                } finally {
                    if (isMounted) {
                        setIsExpensesLoading(false);
                    }
                }
            };

            fetchExpenses();
        }

        return () => {
            isMounted = false;
        };
    }, [getAll, userId]);

    const create = useCallback(async (expense) => {
        try {
            console.log(expense)
            const response = await axios.put(`/expenses`, expense);
            const newExpenseId = response?.data?.id;
            setAllExpenses(prevVal => [...prevVal, { _id: newExpenseId, ...expense }]);
            return newExpenseId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const update = useCallback(async (expense) => {
        try {
            const response = await axios.post(`/expenses`, expense);
            const existingExpenseId = response?.data?.id;
            setAllExpenses(prevVal => [...prevVal.filter(expense => expense._id !== existingExpenseId), { _id: existingExpenseId, ...expense }]);
            return existingExpenseId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const remove = useCallback(async (expenseId) => {
        try {
            await axios.delete(`/expenses/${expenseId}`);
            setAllExpenses(prevVal => [...prevVal.filter(expense => expense._id !== expenseId)]);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <ExpensesContext.Provider
            value={{
                allExpenses,
                isExpensesLoading,
                errorExpenses,

                getAll,
                create,
                update,
                remove
            }}>
            {children}
        </ExpensesContext.Provider>
    </>;
};
