import { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';

import CurrencyInput from '../../../../../../custom-controls/currency-input';
import DatePicker from '../../../../../../custom-controls/date-picker';

import MoneyChip from '../../../../../../custom-controls/chips/money';

const UpsertModal = ({
    verb = "Add",
    expenseId = -1,
    type = 'ad-hoc',
    description = '',
    amount = '',
    date = '',
    disabled = false,
    onAdd,
    onUpdate
}) => {
    const intialValues = {
        type,
        description,
        amount,
        date: date ? dayjs(date) : dayjs()
    };

    const [isAddDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState({ ...intialValues });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleClickOpen = async () => {
        setIsDialogOpen(true);
    };

    const handleDone = async () => {
        setIsDialogOpen(false);
        setFormData({ ...intialValues });

        verb === "Add"
            ? onAdd({ expenseId, ...formData, amount: Number(formData?.amount) })
            : onUpdate({ expenseId, ...formData, amount: Number(formData?.amount) });
    };

    const handleClose = async () => {
        setIsDialogOpen(false);
        setFormData({ ...intialValues });
    };

    const getIcon = () => {
        if (verb === "Add")
            return <Fab
                aria-label="add expense dialog"
                size="small"
                color="primary"
                onClick={handleClickOpen}
                disabled={disabled}
            >
                <AddIcon />
            </Fab>;

        if (verb === 'Update') {
            return <MoneyChip
                amount={amount}
                type={"outlined"}
                sx={{ m: 1 }}
                onClick={handleClickOpen}
                disabled={disabled}
            />;
        }

        return null;
    };

    return <>
        {getIcon()}
        <Dialog open={isAddDialogOpen} onClose={handleClose}>
            <DialogTitle>{`${verb} Expense`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        verb === 'Add'
                            ? 'To add an expense, simply supply a unique description, the amount incurred and the date of the expense.'
                            : 'Update any of the following details and hit Done'
                    }
                </DialogContentText>
                <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ mb: -2, width: '100%' }}>
                            <RadioGroup name="type" value={formData?.type} row onChange={handleChange}>
                                <FormControlLabel value="ad-hoc" control={<Radio />} label="Ad-Hoc/Once Off" />
                                <FormControlLabel value="monthly" control={<Radio />} label="Monthly Recurring" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                autoFocus
                                name="description"
                                id="description"
                                label="Short Description"
                                type="text"
                                fullWidth
                                required
                                onChange={handleChange}
                                value={formData?.description}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <CurrencyInput
                                label="Amount"
                                value={formData?.amount || ''}
                                prefix={"$"}
                                useCurrencyPrefix={true}
                                onChange={(newValue) => setFormData((prev) => ({ ...prev, amount: newValue }))}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <DatePicker
                                label={formData?.type === 'ad-hoc' ? "Date of Expense" : "First Date of Expense"}
                                value={formData?.date}
                                onChange={(newDate) => setFormData((prev) => ({ ...prev, date: newDate }))}
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDone}>Done</Button>
            </DialogActions>
        </Dialog>
    </>;
};

UpsertModal.propTypes = {
    verb: PropTypes.oneOf(['Add', 'Update']),
    expenseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.string,
    disabled: PropTypes.bool,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func
};


export default UpsertModal;