import { useState } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    CircularProgress
} from '@mui/material';

import {
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const SaveButton = ({ onClick, isModified, resetFormData }) => {
    const [status, setStatus] = useState('idle');

    const handleClick = async () => {
        setStatus('loading');
        try {
            await onClick();
            setStatus('success');
            setTimeout(() => setStatus('idle'), 2000); // Reset after 2 seconds
        } catch (error) {
            setStatus('error');
            resetFormData();
            setTimeout(() => setStatus('idle'), 2000); // Reset after 2 seconds
        }
    };

    const renderButtonContent = () => {
        switch (status) {
            case 'loading':
                return <CircularProgress size={24} data-testid="save-button-circle-progress" />;
            case 'success':
                return <CheckCircleIcon data-testid="save-button-check-circle-icon" />;
            case 'error':
                return <ErrorIcon data-testid="save-button-error-icon" />;
            default:
                return <SaveIcon data-testid="save-button-save-icon" />;
        }
    };

    const buttonStyle = () => {
        switch (status) {
            case 'success':
                return { backgroundColor: 'green', color: 'white' };
            case 'error':
                return { animation: 'shake 0.5s', backgroundColor: 'red', color: 'white' };
            default:
                return {};
        }
    };

    return <>
        <Button
            data-testid="save-button"
            startIcon={renderButtonContent()}
            variant="contained"
            size="large"
            onClick={handleClick}
            disabled={!isModified || status === 'loading'}
            style={buttonStyle()}
        >
            Save Changes
        </Button>
    </>;
};

SaveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isModified: PropTypes.bool.isRequired,
    resetFormData: PropTypes.func.isRequired
};

export default SaveButton;
