import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PasswordIcon from '@mui/icons-material/Password';

import SaveButton from '../../custom-controls/save-button';

import { useAccount } from '../../../contexts/AccountContext';

const SignInBox = ({ userId }) => {
    const { get, update } = useAccount();

    const [initialData, setInitialData] = useState({
        email: '',
        first: '',
        last: ''
    });

    const [formData, setFormData] = useState({
        email: '',
        first: '',
        last: ''
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        get(userId)
            .then(account => {
                const accountData = {
                    email: account.data.email || '',
                    first: account.data.first || '',
                    last: account.data.last || ''
                };
                setFormData(accountData);
                setInitialData(accountData);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [get, userId]);

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await update({ ...formData, userId });
            setInitialData(formData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormData = () => {
        setFormData(initialData);
    };

    const isModified = (
        formData.email !== initialData.email ||
        formData.first !== initialData.first ||
        formData.last !== initialData.last
    );

    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={6} md={12} container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="div" sx={{ mt: 3 }}>
                        Sign In Details
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="email"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={formData?.email}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            startIcon={<PasswordIcon />}
                            variant="contained"
                            size="large"
                            disabled>
                            Change Password
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        margin="dense"
                        name="first"
                        id="first"
                        label="Firstname"
                        type="text"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={formData?.first}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        margin="dense"
                        name="last"
                        id="last"
                        label="Lastname"
                        type="text"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={formData?.last}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <SaveButton
                            onClick={handleSave}
                            isModified={isModified}
                            resetFormData={resetFormData}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>;
};

SignInBox.propTypes = {
    userId: PropTypes.string.isRequired
};

export default SignInBox;