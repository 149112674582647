import { createContext, useCallback, useContext } from 'react';
import axios from '../utils/axios';

// Initialize context
const AccountContext = createContext();

// Export the consumer
export const useAccount = () => {
    return useContext(AccountContext);
};

// Export the provider
export const AccountProvider = ({ children }) => {
    const get = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/account/user/${userId}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const update = useCallback(async (formData = {}) => {
        try {
            await axios.post('/account/update', formData);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <AccountContext.Provider
            value={{
                get,
                update
            }}>
            {children}
        </AccountContext.Provider>
    </>;
};
