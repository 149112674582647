import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    FormControl,
    InputAdornment,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';

import DatePicker from '../../../../../../custom-controls/date-picker';

import MoneyChip from '../../../../../../custom-controls/chips/money';

const UpsertModal = ({
    verb = "Add",
    incomeId = -1,
    clientId = '',
    clients = [],
    projectId = '',
    projects = [],
    amount = '',
    invoicedDate = undefined,
    invoiceSettledDate = undefined,
    hours = '',
    minutes = '',
    onAdd,
    onUpdate,
    disabled
}) => {
    const intialValues = {
        clientId,
        projectId,
        amount,
        invoicedDate: invoicedDate ? dayjs(invoicedDate) : dayjs() ,
        invoiceSettledDate: invoiceSettledDate ? dayjs(invoiceSettledDate) : dayjs() ,
        hours,
        minutes
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState(intialValues);
    const [rate, setRate] = useState(0);
    const [calculatedAmount, setCalculatedAmount] = useState(0.00);

    // Auto-select client when there is only one in the array
    useEffect(() => {
        if (clients.length === 1) {
            const singleClientId = clients[0]._id;
            setFormData(prev => ({ ...prev, clientId: singleClientId }));
        }
    }, [clients]);

    // Auto-select project and set rate when client is selected
    useEffect(() => {
        if (formData.clientId) {
            const clientProjects = projects.filter(project => project.clientId === formData.clientId);
            if (clientProjects.length === 1) {
                const singleProjectId = clientProjects[0]._id;
                const projectRate = clientProjects[0].rate;

                setFormData(prev => ({ ...prev, projectId: singleProjectId }));
                setRate(projectRate);
            }
        }
    }, [formData.clientId, projects]);

    useEffect(() => {
        if (amount && !isNaN(amount)) {
            setCalculatedAmount(amount);
        }
    }, [amount]);

    const handleChange = event => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if (name === 'projectId')
            setRate(projects?.filter(project => project._id === value)[0]?.rate)

        calculateCost(formData?.hours, formData?.minutes, rate);
    };

    const handleHoursChange = event => {
        setFormData((prev) => ({ ...prev, hours: event.target.value }));

        calculateCost(event.target.value, formData?.minutes, rate);
    };

    const handleMinutesChange = event => {
        setFormData((prev) => ({ ...prev, minutes: event.target.value }));

        calculateCost(formData?.hours, event.target.value, rate);
    };

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDone = async () => {
        setIsDialogOpen(false);
        setFormData(intialValues);

        verb === "Add"
            ? onAdd({ incomeId, ...formData, hours: Number(formData?.hours), minutes: Number(formData?.minutes), amount: calculatedAmount })
            : onUpdate({ incomeId, ...formData, hours: Number(formData?.hours), minutes: Number(formData?.minutes), amount: calculatedAmount });
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setFormData({ ...intialValues });
    };

    const generateMenuItems = items => {
        return items.map(item => <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>)
    };

    const calculateCost = (hours = 0, minutes = 0, rate = 0) => {
        // Ensure all inputs are valid numbers
        const numHours = Number(hours);
        const numMinutes = Number(minutes);
        const numRate = Number(rate);

        const totalHours = numHours + (numMinutes / 60);
        const amount = (totalHours * numRate);

        setCalculatedAmount(amount);
    };

    const getIcon = () => {
        if (verb === "Add")
            return <Fab
                aria-label="add income dialog"
                size="small"
                color="primary"
                onClick={handleClickOpen}
                disabled={disabled}
            >
                <AddIcon />
            </Fab>;

        if (verb === 'Update') {
            return <MoneyChip
                amount={amount}
                type={"outlined"}
                sx={{ m: 1 }}
                onClick={handleClickOpen}
                disabled={disabled}
            />;
        }

        return null;
    };

    return <>
        {getIcon()}
        <Dialog open={isDialogOpen} onClose={handleClose}>
            <DialogTitle>{`${verb} Income`}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    {
                        verb === 'Add'
                            ? 'To add an income, simply specify the client, the amount earned and the date of completing this job.'
                            : 'Update any of the following details and hit Done'
                    }
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="client-label">Client</InputLabel>
                            <Select
                                autoFocus
                                labelId="client-label"
                                id="client-select"
                                name="clientId"
                                value={formData?.clientId}
                                onChange={handleChange}
                                label="Client"
                                required
                            >
                                {generateMenuItems(clients)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="project-label">Project</InputLabel>
                            <Select
                                labelId="project-label"
                                id="project-select"
                                name="projectId"
                                value={formData?.projectId}
                                onChange={handleChange}
                                label="Project"
                                required
                                disabled={!formData || !formData?.clientId || formData?.clientId === ''}
                            >
                                {generateMenuItems(projects)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <DatePicker
                                label={"Date of Invoice"}
                                value={formData?.invoicedDate}
                                onChange={(newDate) => setFormData((prev) => ({ ...prev, invoicedDate: newDate }))}
                                required
                                disabled={!formData || !formData?.projectId || formData?.projectId === ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <DatePicker
                                label={"Date Invoice was Settled"}
                                value={formData?.invoiceSettledDate}
                                onChange={(newDate) => setFormData((prev) => ({ ...prev, invoiceSettledDate: newDate }))}
                                disabled={!formData || !formData?.projectId || formData?.projectId === ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" light />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                name="hours"
                                id="hours"
                                label="Hours"
                                type="number"
                                required
                                inputProps={{ min: "0", step: "1" }}
                                onChange={handleHoursChange}
                                value={formData?.hours}
                                disabled={!formData || !formData?.projectId || formData?.projectId === ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                name="minutes"
                                id="minutes"
                                label="Minutes"
                                type="number"
                                required
                                inputProps={{ min: "0", max: "59", step: "1" }}
                                onChange={handleMinutesChange}
                                value={formData?.minutes}
                                disabled={!formData || !formData?.projectId || formData?.projectId === ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                name="rate"
                                id="rate"
                                label="Hourly Rate"
                                type="number"
                                value={rate}
                                disabled
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">of $</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">per hour</InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                name="amount"
                                id="amount"
                                label="Total"
                                type="number"
                                value={Number(calculatedAmount).toFixed(2)}
                                disabled
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDone}>Done</Button>
            </DialogActions>
        </Dialog>
    </>
};

UpsertModal.propTypes = {
    verb: PropTypes.oneOf(['Add', 'Update']).isRequired,
    incomeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clientId: PropTypes.string,
    clients: PropTypes.array,
    projectId: PropTypes.string,
    projects: PropTypes.array,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    invoicedDate: PropTypes.string,
    invoiceSettledDate: PropTypes.string,
    hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    disabled: PropTypes.bool
};

export default UpsertModal;