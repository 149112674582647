import { useEffect } from 'react';

import {
    Box,
    Grid,
    Paper,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';

import { useAuth } from '../../../../../contexts/AuthContext';
import { useExpenses } from '../../../../../contexts/ExpensesContext';

import ExpensesDataGrid from './data-grid';

import UpsertModal from './modals/upsert';

const mutedStyle = {
    fontWeight: 'normal',
    color: 'text.secondary',
};

const Expenses = () => {
    const { userId } = useAuth();
    const { allExpenses, isExpensesLoading, errorExpenes, create, update, remove } = useExpenses();

    const handleAddNewExpense = async ({ type, description, amount, date }) => {
        await create({ type, description, amount, date, userId });
    };

    const handleUpdateExpense = async ({ expenseId, type, description, amount, date }) => {
        await update({ expenseId, type, description, amount, date, userId });
    };

    const handleRemoveExpense = async ({ expenseId }) => {
        await remove(expenseId);
    };

    const generateLoadingIndicator = () => {
        return <>
            <TableRow>
                <TableCell align="center" sx={mutedStyle}>Loading Expenses...</TableCell>
            </TableRow>
        </>;
    };

    const generateErrorIndicator = error => {
        return <>
            <TableRow>
                <TableCell align="center" sx={{ ...mutedStyle, color: 'red' }}>Error: {error.message}</TableCell>
            </TableRow>;
        </>;
    };

    const generateExpenses = () => {
        if (isExpensesLoading) {
            return <>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table aria-label="loading incomes table">
                        <TableBody>
                            {generateLoadingIndicator()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>;
        }

        if (errorExpenes) {
            return <>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table aria-label="errored incomes table">
                        <TableBody>
                            {generateErrorIndicator(errorExpenes)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>;
        }

        if (allExpenses && allExpenses?.length > 0) {
            return <>
                <ExpensesDataGrid
                    expenses={allExpenses}
                    onUpdate={handleUpdateExpense}
                    onRemove={handleRemoveExpense} />
            </>;
        }

        return <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible incomes table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" sx={mutedStyle}>No expenses set up yet. Add some now.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>;
    };

    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid container justifyContent="right" sx={{ m: 2 }}>
                <Grid item>
                    <UpsertModal
                        verb="Add"
                        onAdd={handleAddNewExpense}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                {generateExpenses()}
            </Grid>
        </Box>
    </>;
};

export default Expenses;