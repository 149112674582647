import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
	AppBar,
	IconButton,
	Avatar,
	Popover,
	List,
	ListItemButton,
} from '@mui/material';

import OnlineIndicator from '../OnlineIndicator';

import { useAuth } from '../../contexts/AuthContext';

const Header = () => {
	const navigate = useNavigate();
	const { userId, signOut } = useAuth();

	const [anchorEl, setAnchorEl] = useState(null);
	const [popover, setPopover] = useState(false);

	if (!userId)
		return null;

	const openPopover = e => {
		setPopover(true);
		setAnchorEl(e.currentTarget);
	};

	const closePopover = () => {
		setPopover(false);
		setAnchorEl(null);
	};

	const handleAccountClicked = () => {
		navigate('/account');
		closePopover();
	};

	const handleHomeClicked = () => {
		navigate('/');
		closePopover();
	};

	const handleLogoutClicked = () => {
		signOut();
		navigate('/');
	}

	return <>
		<AppBar className='header' position='static'>
			<h1>
				<b>GIG</b>antics
			</h1>

			<IconButton onClick={openPopover}>
				<OnlineIndicator online={typeof userId !== 'undefined' && userId !== null}>
					{/* <Avatar src={account?.email || ''} alt={account?.email || ''} /> */}
					<Avatar />
				</OnlineIndicator>
			</IconButton>

			<Popover
				anchorEl={anchorEl}
				open={popover}
				onClose={closePopover}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<List style={{ minWidth: '100px' }}>
					<ListItemButton onClick={handleHomeClicked}>Home</ListItemButton>
					<ListItemButton onClick={handleAccountClicked}>Account</ListItemButton>
					<ListItemButton onClick={handleLogoutClicked}>Sign Out</ListItemButton>
				</List>
			</Popover>

		</AppBar>
	</>
};

export default Header;