import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Currencies from '../../custom-controls/currencies';
import LocalizedTimezones from '../../custom-controls/localized-timezones';

import SaveButton from '../../custom-controls/save-button';

import { useDefaults } from '../../../contexts/DefaultsContext';

const DefaultsBox = ({ userId }) => {
    const { get, update } = useDefaults();

    const [initialData, setInitialData] = useState({
        currency: '',
        timezone: ''
    });

    const [formData, setFormData] = useState({
        currency: '',
        timezone: ''
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        get(userId)
            .then(defaults => {
                const dafaultsData = {
                    currency: defaults.data.currency || '',
                    timezone: defaults.data.timezone || ''
                };
                setFormData(dafaultsData);
                setInitialData(dafaultsData);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [get, userId]);

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await update({ ...formData, userId });
            setInitialData(formData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormData = () => {
        setFormData(initialData);
    };

    const isModified = (
        formData.currency !== initialData.currency ||
        formData.timezone !== initialData.timezone
    );

    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 5 }}
        >
            <Grid xs={6} md={12} container item spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="div">
                        Defaults
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Currencies
                        name="currency"
                        selectedCurrencySymbol={formData.currency}
                        onChange={handleChange}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizedTimezones
                        name="timezone"
                        selectedTimezone={formData.timezone}
                        onChange={handleChange}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <SaveButton
                            onClick={handleSave}
                            isModified={isModified}
                            resetFormData={resetFormData}
                        />
                    </Box>
                </Grid>
            </Grid>

        </Box>
    </>;
};

DefaultsBox.propTypes = {
    userId: PropTypes.string.isRequired
};

export default DefaultsBox;