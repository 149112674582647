import { useState } from 'react';

import PropTypes from 'prop-types';

import {
    Box,
    Collapse,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useProjects } from '../../../../../../contexts/ProjectsContext';

import ProjectHeader from '../project-header';

const mutedStyle = {
    fontWeight: 'normal',
    color: 'text.secondary',
};

const ClientHeader = ({ client, clients }) => {
    const { allProjects, isProjectsLoading, errorProjects } = useProjects();

    const [isClientClosed, setIsClientClosed] = useState(false);

    const generateLoadingIndicator = () => {
        return <>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={mutedStyle}>Loading...</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>;
    };

    const generateErrorIndicator = error => {
        return <>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" sx={{ ...mutedStyle, color: 'red' }}>Error: {error.message}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>;
    };

    const generateArrowsHeader = open => {
        return <>
            <TableCell component="th" scope="row" style={{ width: 15 }}>
                <IconButton
                    aria-label="expand client row"
                    size="small"
                    onClick={() => setIsClientClosed(!isClientClosed)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </>;
    };

    const generateClientControl = client => {
        return <>
            <TableCell component="td" scope="row">
                <Typography variant="h6">
                    {client?.name}
                </Typography>
            </TableCell>
        </>;
    };

    const generateProjectsContent = client => {
        if (isProjectsLoading) {
            return generateLoadingIndicator();
        }

        if (errorProjects) {
            return generateErrorIndicator(errorProjects);
        }

        if (allProjects.length > 0 && allProjects?.filter(p => p.clientId === client._id)?.length >= 1) {
            return allProjects.map(project => <ProjectHeader key={`project-header-${project._id}`} project={project} clients={clients} projects={allProjects} />);
        }

        return <Typography variant="body1" style={{ marginLeft: 16 }} sx={{ ...mutedStyle, mb: 2, mt: 1 }}>
            No projects available for {client?.name}. Add some now.
        </Typography>;
    };

    return <>
        <TableRow key={client._id + '-master'} sx={{ '& > *': { borderBottom: 'unset' } }}>
            {generateArrowsHeader(isClientClosed)}
            {generateClientControl(client)}
        </TableRow>
        <TableRow key={client._id + '-project-details'}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
                <Collapse in={!isClientClosed} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1, mb: 1 }}>
                        {generateProjectsContent(client)}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>;
};

ClientHeader.propTypes = {
    client: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    clients: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ClientHeader;