import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

const ClientsContext = createContext();

export const useClients = () => {
    return useContext(ClientsContext);
};

export const ClientsProvider = ({ children }) => {
    const [allClients, setAllClients] = useState([]);
    const [isClientsLoading, setIsClientsLoading] = useState(false);
    const [errorClients, setErrorClients] = useState(null);

    const [userId] = useLocalStorage('userId');

    const getAll = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/clients/user/${userId}`);
            setAllClients(response?.data?.data)
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (userId && userId !== null) {
            const fetchClients = async () => {
                setIsClientsLoading(true);
                try {
                    const fetchedClients = await getAll(userId);
                    if (isMounted) {
                        setAllClients(fetchedClients);
                    }
                } catch (err) {
                    if (isMounted) {
                        setErrorClients(err);
                    }
                } finally {
                    if (isMounted) {
                        setIsClientsLoading(false);
                    }
                }
            };

            fetchClients();
        }

        return () => {
            isMounted = false;
        };
    }, [getAll, userId]);

    const addTemp = () => {
        try {
            setAllClients(prevClients => [
                ...prevClients?.filter(client => client._id !== -1), {
                    _id: -1,
                    name: "",
                    userId: userId
                }
            ]);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const cancelTemp = () => {
        try {
            setAllClients(prevClients => [...prevClients.filter(client => client._id !== -1)])
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const create = useCallback(async (client) => {
        try {
            const response = await axios.put(`/clients`, client);
            const newClientId = response?.data?.data?._id;
            setAllClients(prevVal => [...prevVal.filter(client => client._id !== -1), { _id: newClientId, ...client }]);
            return newClientId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const update = useCallback(async (client) => {
        try {
            const response = await axios.post(`/clients`, client);
            const existingClientId = response?.data?.data?._id;
            setAllClients(prevVal => [...prevVal.filter(client => client._id !== existingClientId), { _id: existingClientId, ...client }]);
            return existingClientId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const remove = useCallback(async (clientId) => {
        try {
            await axios.delete(`/clients/${clientId}`);
            setAllClients(prevVal => [...prevVal.filter(client => client._id !== clientId)]);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <ClientsContext.Provider
            value={{
                allClients,
                isClientsLoading,
                errorClients,

                getAll,

                addTemp,
                cancelTemp,
                create,

                update,
                remove
            }}>
            {children}
        </ClientsContext.Provider>
    </>;
};
