import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

const ProjectsContext = createContext();

export const useProjects = () => {
    return useContext(ProjectsContext);
};

export const ProjectsProvider = ({ children }) => {
    const [allProjects, setAllProjects] = useState([]);
    const [isProjectsLoading, setIsProjectsLoading] = useState(false);
    const [errorProjects, setErrorProjects] = useState(null);

    const [userId] = useLocalStorage('userId');

    const getAll = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/projects/user/${userId}`);
            setAllProjects(response?.data?.data)
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (userId && userId !== null) {
            const fetchProjects = async () => {
                setIsProjectsLoading(true);
                try {
                    const fetchedProjects = await getAll(userId);
                    if (isMounted) {
                        setAllProjects(fetchedProjects);
                    }
                } catch (err) {
                    if (isMounted) {
                        setErrorProjects(err);
                    }
                } finally {
                    if (isMounted) {
                        setIsProjectsLoading(false);
                    }
                }
            };

            fetchProjects();
        }

        return () => {
            isMounted = false;
        };
    }, [getAll, userId]);

    const addTemp = clientId => {
        try {
            setAllProjects(prevProjects => [
                ...prevProjects?.filter(project => project._id !== -1), {
                    _id: -1,
                    clientId,
                    name: '',
                    type: '',
                    rate: 0.00,

                    created: undefined,
                    completed: undefined,
                    closed: undefined
                }
            ])
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const cancelTemp = () => {
        try {
            setAllProjects(prevProjects => [...prevProjects.filter(project => project._id !== -1)])
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const create = useCallback(async (project) => {
        try {
            const response = await axios.put(`/projects`, { ...project, userId: userId, created: new Date().toUTCString() });
            const newProjectId = response?.data?.data?._id;
            setAllProjects(prevProjects => [...prevProjects.filter(project => project._id !== -1), { _id: newProjectId, ...project }]);
            return newProjectId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const update = useCallback(async (project) => {
        try {
            const response = await axios.post(`/projects`, { ...project, userId: userId, created: new Date().toUTCString() });
            const existingProjectId = response?.data?.data?._id;
            setAllProjects(prevProjects => [...prevProjects.filter(prevProject => prevProject._id !== existingProjectId), { _id: existingProjectId, ...project, }]);
            return existingProjectId;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    const remove = useCallback(async (projectId) => {
        try {
            await axios.delete(`/projects/${projectId}`);
            setAllProjects(prevVal => [...prevVal.filter(project => project._id !== projectId)]);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <ProjectsContext.Provider
            value={{
                allProjects,
                isProjectsLoading,
                errorProjects,

                getAll,

                addTemp,
                cancelTemp,
                create,

                update,
                remove
            }}>
            {children}
        </ProjectsContext.Provider>
    </>;
};
