import PropTypes from 'prop-types';

import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';

import dayjs from 'dayjs';

import UpsertModal from '../modals/upsert';

import MoneyChip from '../../../../../custom-controls/chips/money';
import TimeChip from '../../../../../custom-controls/chips/time';

const IncomeTable = ({ incomes, clients, projects, onUpdate, onRemove }) => {
    console.log(incomes)
    const getMonthNames = () => {
        return Array.from({ length: 12 }, (_, i) => dayjs(new Date(new Date().getFullYear(), i, 1)).format('MMMM'));
    };

    const monthNames = getMonthNames();

    // Filters and sorts incomes for a given month. First, it filters incomes based on the month name.
    // Then, it sorts the filtered incomes by their invoicedDate in ascending order (earliest first).
    const getIncomesForMonth = (incomes, monthName) => {
        const currentYear = dayjs().format('YYYY'); //  Todo, replace this with a variable at some point
        return incomes
            .filter(income => {
                // Extract both month and year from the invoicedDate
                const incomeMonth = dayjs(income.invoicedDate).format('MMMM');
                const incomeYear = dayjs(income.invoicedDate).format('YYYY');

                // Check if the income's month and year match the specified month and current year
                return incomeMonth === monthName && incomeYear === currentYear;
            })
            .sort((a, b) => dayjs(a.invoicedDate).isAfter(dayjs(b.invoicedDate)) ? 1 : -1);
    };

    const generateAmountUpdateChip = ({ income }) => {
        return <UpsertModal
            key={`upsert-income-update-${income?._id}`}
            verb={'Update'}
            incomeId={income?._id}
            clientId={income?.clientId}
            clients={clients}
            projectId={income?.projectId}
            projects={projects}
            amount={income?.amount}
            invoicedDate={income?.invoicedDate}
            invoiceSettledDate={income?.invoiceSettledDate}
            hours={income?.hours}
            minutes={income?.minutes}

            onUpdate={onUpdate}
        />;
    };

    const generateAmountChip = ({ amount, type }) => {
        return <>
            <MoneyChip
                amount={amount}
                type={type}
                sx={{ m: 1 }}
            />
        </>;
    };

    const generateTimeChip = ({ id, hours, minutes, type }) => {
        return <TimeChip
            key={`time-${hours}-${minutes}`}
            hours={hours}
            minutes={minutes}
            type={type}
            sx={{ m: 1 }}
        />;
    };

    const generateMonthRow = monthName => {
        return <TableCell key={`month-label-${monthName}`} align="center">
            <b>{monthName}</b>
        </TableCell>;
    };

    const generateHeader = () => {
        if (!incomes || incomes.length <= 0)
            return;

        return <>
            <TableRow>
                <TableCell>&nbsp;</TableCell>
                {monthNames.map(month => generateMonthRow(month))}
            </TableRow>
        </>;
    };

    const generateMonthlyAmountsBreakdown = () => {
        if (!incomes || incomes.length <= 0)
            return;

        return <>
            <TableRow>
                <TableCell align="left"><b>Amounts</b></TableCell>
                {
                    monthNames.map(month => {
                        const thisMonthsIncome = getIncomesForMonth(incomes, month);

                        if (thisMonthsIncome?.length <= 0)
                            return <TableCell key={`this-months-income-empty-${month}`}>&nbsp;</TableCell>;

                        return <TableCell
                            key={`this-months-income-${month}`}
                            align="center">{thisMonthsIncome.map(incomeForMonth => generateAmountUpdateChip({ income: incomeForMonth, type: 'outlined' }))}</TableCell>
                    })
                }
            </TableRow>
        </>;
    };

    const generateMonthlyTimeBreakdown = () => {
        if (!incomes || incomes.length <= 0)
            return;

        return <>
            <TableRow>
                <TableCell align="left"><b>Time</b></TableCell>
                {
                    monthNames.map(month => {
                        const thisMonthsIncome = getIncomesForMonth(incomes, month);

                        if (thisMonthsIncome?.length <= 0)
                            return <TableCell key={`this-months-time-empty-${month}`}>&nbsp;</TableCell>;

                        return <TableCell
                            key={`this-months-time-${month}`}
                            align="center">{thisMonthsIncome.map(incomeForMonth => generateTimeChip({ id: incomeForMonth?._id, hours: incomeForMonth?.hours, minutes: incomeForMonth?.minutes, type: 'default' }))}</TableCell>
                    })
                }
            </TableRow>
        </>;
    };

    const generateTotalAmountsBreakdown = () => {
        if (!incomes || incomes.length <= 0)
            return;

        return <>
            <TableRow>
                <TableCell align="left"><b>Total Amounts</b></TableCell>
                {
                    monthNames.map(month => {
                        const thisMonthsIncome = getIncomesForMonth(incomes, month);

                        if (thisMonthsIncome?.length <= 0)
                            return <TableCell key={`this-months-income-total-empty-${month}`}>&nbsp;</TableCell>;

                        const totalAmount = thisMonthsIncome.reduce((accumulator, currentItem) => {
                            return accumulator + currentItem.amount;
                        }, 0);

                        return <TableCell
                            key={`this-months-income-total-${month}`}
                            align="center">{generateAmountChip({ amount: totalAmount, type: 'default' })}</TableCell>
                    })
                }
            </TableRow>
        </>;
    };

    const generateTotalTimeBreakdown = () => {
        if (!incomes || incomes.length <= 0)
            return;

        return <>
            <TableRow>
                <TableCell align="left"><b>Total Time</b></TableCell>
                {
                    monthNames.map(month => {
                        const thisMonthsIncome = getIncomesForMonth(incomes, month);

                        if (thisMonthsIncome?.length <= 0)
                            return <TableCell key={`this-months-time-total-empty-${month}`}>&nbsp;</TableCell>;

                        const totalTime = thisMonthsIncome.reduce((accumulator, currentTime) => {
                            accumulator.hours += currentTime.hours;
                            accumulator.minutes += currentTime.minutes;

                            // Convert 60 minutes into 1 hour
                            if (accumulator.minutes >= 60) {
                                accumulator.hours += Math.floor(accumulator.minutes / 60);
                                accumulator.minutes %= 60;
                            }

                            return accumulator;
                        }, { hours: 0, minutes: 0 });

                        return <TableCell
                            key={`this-months-time-total-${month}`}
                            align="center">{generateTimeChip({ ...totalTime, type: 'default', id: month })}</TableCell>
                    })
                }
            </TableRow>
        </>;
    };

    const generateNoIncomesWarning = () => {
        if (incomes && incomes.length > 0)
            return;

        return <TableRow>
            <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                No income recorded yet. Add some now.
            </TableCell>
        </TableRow>
    };

    return <>
        <Table aria-label="incomes">
            <TableBody sx={{ '& > :last-child > *': { borderBottom: 'none' } }}>
                {generateNoIncomesWarning()}
                {generateHeader()}
                {generateMonthlyAmountsBreakdown()}
                {generateMonthlyTimeBreakdown()}
                {generateTotalAmountsBreakdown()}
                {generateTotalTimeBreakdown()}
            </TableBody>
        </Table>
    </>;
};

IncomeTable.propTypes = {
    incomes: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default IncomeTable;
