import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const formatToCurrency = input => {
    // Convert input to a string if it's not already
    if (typeof input !== 'string') {
        input = String(input);
    }

    if (!input)
        return '0.00';

    if (input === '') {
        return '0.00'; // Set to 0.00 if input is empty
    } else if (input?.includes('.')) {
        const parts = input?.split('.');
        if (parts[1].length === 1) { // Check if there's only one digit after the decimal
            return input + '0'; // Add an extra zero
        }
    } else if (!input?.includes('.')) {
        return input + '.00'; // Add .00 if there's no decimal point
    }

    return input;
};

const CurrencyInput = ({ value, onChange, prefix = '$', useCurrencyPrefix = false, suffix = '', size, sx, label }) => {
    const [inputValue, setInputValue] = useState(value ? value : '');

    useEffect(() => {
        setInputValue(formatToCurrency(inputValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = e => {
        const value = e.target.value;
        // Allow only numbers and decimal points
        let newValue = value.replace(/[^0-9.]+/g, '');

        // Split the string into whole and decimal parts
        const parts = newValue.split('.');
        if (parts.length > 1) {
            newValue = parts[0] + '.' + parts[1].slice(0, 2); // Keep only two decimal places
        }

        // Check if the newValue is a valid float or empty string (for backspace)
        if (!newValue || !isNaN(parseFloat(newValue))) {
            setInputValue(newValue);
            onChange(newValue);
        }
    };

    const handleFocus = e => {
        e.target.select();
    };

    const handleInputBlur = () => {
        setInputValue(formatToCurrency(inputValue));
    };

    return <>
        <TextField
            label={label}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleInputBlur}
            size={size}
            sx={sx}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            InputProps={{
                startAdornment: useCurrencyPrefix && (
                    <InputAdornment position="start">{prefix}</InputAdornment>
                ),
                endAdornment: suffix && (
                    <InputAdornment position="end">{suffix}</InputAdornment>
                ),
                inputMode: 'numeric',
                pattern: '[0-9.]*'
            }}
        />
    </>;
};

CurrencyInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    useCurrencyPrefix: PropTypes.bool,
    suffix: PropTypes.string,
    size: PropTypes.string,
    sx: PropTypes.object,
    label: PropTypes.string,
    prefix: PropTypes.string
};

export default CurrencyInput;
