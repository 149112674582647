import React, { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './components/header';
import App from './components/app';
import Account from './components/account';

import { AuthProvider } from './contexts/AuthContext';
import SnackbarProvider from './contexts/SnackbarContext';

import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';
import './styles/index.css';

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <StrictMode>
            <SnackbarProvider>
                <AuthProvider>
                    <CssBaseline />
                    <BrowserRouter>
                        <Header />
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route path="/" element={<App />} exact={true} />
                                <Route path="/account" element={<Account />} exact={true} />
                                {/* Other routes */}
                            </Routes>
                        </Suspense>
                    </BrowserRouter>
                </AuthProvider>
            </SnackbarProvider>
        </StrictMode>
    );
}
