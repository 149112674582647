import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useDefaults } from '../../../../contexts/DefaultsContext';

const MoneyChip = ({ amount, type, onClick, disabled, sx, color }) => {
    const { currency } = useDefaults();

    const handleClick = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    return <>
        <Chip
            label={`${currency}${Number(amount)?.toFixed(2)}`}
            variant={type}
            sx={{ ...sx }}
            color={color}
            onClick={handleClick}
            disabled={disabled}
        />
    </>;
};

MoneyChip.propTypes = {
    amount: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['default', 'outlined', 'filled']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    color: PropTypes.string
};

MoneyChip.defaultProps = {
    disabled: false,
    sx: {}
};

export default MoneyChip;
