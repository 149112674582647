import Main from './body/main';
import Anonymous from './body/anonymous';

import { useAuth } from '../../contexts/AuthContext';

const App = () => {
    const { userId } = useAuth();

    return userId
        ? <Main />
        : <Anonymous />;
};

export default App;
