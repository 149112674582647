import { useCallback, createContext, useContext, useEffect, useState } from 'react';
import axios from '../utils/axios';

import useLocalStorage from '../hooks/useLocalStorage';

import {
    DEFAULT_CURRENCY,
    DEFAULT_CURRENCIES,
    DEFAULT_TIMEZONE
} from '../constants';

// Initialize context
const DefaultsContext = createContext();

// Export the consumer
export const useDefaults = () => {
    return useContext(DefaultsContext);
};

// Export the provider
export const DefaultsProvider = ({ children }) => {
    const [currency, setCurrency] = useState(DEFAULT_CURRENCIES.find(currency => currency.code === DEFAULT_CURRENCY)?.symbol);
    const [timezone, setTimeZone] = useState(DEFAULT_TIMEZONE);
    
	const [userId] = useLocalStorage('userId');

    const get = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/defaults/user/${userId}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        if (userId && userId !== null) {
            get(userId)
                .then(defaults => {
                    const accountData = {
                        currency: DEFAULT_CURRENCIES.find(currency => currency.code === defaults?.data?.currency)?.symbol || DEFAULT_CURRENCY,
                        timezone: defaults?.data?.timezone || DEFAULT_TIMEZONE
                    }
                    setCurrency(accountData.currency);
                    setTimeZone(accountData.timezone);
                });
        }
    }, [get, userId])

    const update = useCallback(async (defaults) => {
        try {
            await axios.post('/defaults/update', defaults);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, []);

    return <>
        <DefaultsContext.Provider
            value={{
                currency,
                timezone,

                get,
                update
            }}>
            {children}
        </DefaultsContext.Provider>
    </>;
};
