import {
	useState,
} from 'react';

import PropTypes from 'prop-types';

import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
	CircularProgress,
} from '@mui/material';

import {
	Visibility,
	VisibilityOff
} from '@mui/icons-material';

import { useAuth } from '../../../../../contexts/AuthContext';
import { useSnackbar } from '../../../../../contexts/SnackbarContext';

const textFieldProps = {
	margin: "dense",
	type: "text",
	fullWidth: true,
	required: true
};

const Register = ({ toggleForm }) => {
	const { register } = useAuth();
	const { showSnackbar, SNACKBAR_SEVERITIES } = useSnackbar();

	const [formData, setFormData] = useState({ first: '', last: '', email: '', password: '' });
	const [loading, setLoading] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({ first: false, last: false, email: false, password: false });
	const [showPassword, setShowPassword] = useState(false);

	const isValidEmail = email => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const isValidPassword = password => {
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
		return passwordRegex.test(password);
	};

	const validateForm = () => {
		const errors = {
			first: formData?.first?.length < 4,
			last: formData?.last?.length < 4,
			email: !formData?.email || !isValidEmail(formData?.email),
			password: !formData?.password || !isValidPassword(formData?.password)
		};
		setFieldErrors(errors);

		const firstError = errors.first ? 'First name must be at least 4 characters.' : '';
		const lastError = errors.last ? 'Last name must be at least 4 characters.' : '';
		const emailError = errors.email ? 'Please enter a valid email address.' : '';
		const passwordError = errors.password ? 'Password must be at least 8 characters long and include at least one uppercase, lowercase, number, and special character.' : '';

		const errorMessages = [firstError, lastError, emailError, passwordError].filter(Boolean);

		if (errorMessages) {
			showSnackbar(errorMessages.map(x => { return { text: x } }), SNACKBAR_SEVERITIES.ERROR);
		}

		return Object.values(errors).some(e => e);
	};

	const handleSubmit = async () => {
		if (validateForm())
			return;

		setLoading(true);

		try {
			const response = await register(formData);
			if (response?.data?.message) {
				showSnackbar(response?.data?.message, SNACKBAR_SEVERITIES.ERROR);
			}
		} catch (err) {
			showSnackbar('Registration failed. Please try again.', SNACKBAR_SEVERITIES.ERROR);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prev => ({ ...prev, [name]: value }));
		setFieldErrors(prev => ({ ...prev, [name]: false }));
	};

	const handleEnterPressed = e => {
		if (e.key === 'Enter' && formData.first && formData.last && formData.email && formData.password) {
			handleSubmit();
		}
	};

	const disabledButton = Object.values(formData).some(val => !val) || !isValidEmail(formData.email);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return <>
		<Box textAlign="center">
			<Typography variant="h5" sx={{ mb: 2 }}>Create A New Account</Typography>
		</Box>
		<TextField
			data-testid="firstname-input"
			label='Firstname'
			name='first'
			onChange={handleChange}
			onKeyPress={handleEnterPressed}
			value={formData.first}
			disabled={loading}
			error={fieldErrors.first}
			autoFocus
			{...textFieldProps}
		/>
		<TextField
			data-testid="lastname-input"
			label='Lastname'
			name='last'
			onChange={handleChange}
			onKeyPress={handleEnterPressed}
			value={formData.last}
			disabled={loading}
			error={fieldErrors.last}
			{...textFieldProps}
		/>
		<TextField
			data-testid="email-input"
			label='Email'
			name='email'
			onChange={handleChange}
			onKeyPress={handleEnterPressed}
			value={formData.email}
			disabled={loading}
			error={fieldErrors.email}
			{...textFieldProps}
		/>
		<TextField
			data-testid="password-input"
			label='Password'
			name='password'
			onChange={handleChange}
			onKeyPress={handleEnterPressed}
			value={formData.password}
			disabled={loading}
			error={fieldErrors.password}
			{...textFieldProps}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={togglePasswordVisibility}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
		<Button
			data-testid="register-button"
			onClick={handleSubmit}
			disabled={disabledButton || loading}
			variant="contained"
			size="large"
			fullWidth
			sx={{ mt: 2 }}
			startIcon={loading && <CircularProgress size={15} />}
		>
			Register
		</Button>
		<Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
			<Typography variant="body2">
				Already have an account?&nbsp;
				<Link href="#" onClick={toggleForm} style={{ cursor: 'pointer' }}>
					Sign In
				</Link>
			</Typography>
		</Box>
	</>;
};

Register.propTypes = {
	toggleForm: PropTypes.func.isRequired,
};

export default Register;
