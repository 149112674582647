import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import UndoIcon from '@mui/icons-material/Undo';

import CurrencyInput from '../../../../custom-controls/currency-input';
import IncomeFrequency from '../../../../custom-controls/income-frequency';
import LocalizedDate from '../../../../custom-controls/localized-date';

import { useDefaults } from '../../../../../contexts/DefaultsContext';

const mutedStyle = {
    fontWeight: 'normal',
    fontSize: '0.775rem',
    color: 'text.secondary',
};

const ProjectRow = ({ project, allProjects, onAddProject, onUpdateProject, onRemoveProject, onCancelAdd }) => {
    const { currency } = useDefaults();

    const [projectName, setProjectName] = useState(project?.name ? project?.name : '');
    const [projectType, setProjectType] = useState(project?.type ? project?.type : '');
    const [projectRate, setProjectRate] = useState(project?.rate ? project?.rate : 0);
    const [isNameChanged, setIsNameChanged] = useState(false);
    const [isTypeChanged, setIsTypeChanged] = useState(false);
    const [isRateChanged, setIsRateChanged] = useState(false);
    const [isDuplicateName, setIsDuplicateName] = useState(false);

    const originalName = project?.name;
    const originalType = project?.type;
    const originalRate = project?.rate;
    const textFieldStyle = isDuplicateName ? { animation: 'shake 0.5s', color: 'red' } : {};

    useEffect(() => {
        setIsNameChanged(projectName !== originalName);
        setIsTypeChanged(projectType !== originalType);
        setIsRateChanged(projectRate !== originalRate);
        setIsDuplicateName(allProjects.some(c => c._id !== project._id && c.name.trim() === projectName.trim()));
    }, [projectName, originalName, allProjects, project._id, projectType, originalType, projectRate, originalRate]);

    const generateDates = projectId => {
        if (projectId && projectId === -1)
            return <TableCell colSpan={3} align="center" sx={mutedStyle} style={{ visibility: "hidden" }}>
                Project Not Yet Started
            </TableCell>;

        return typeof project?.completed === 'undefined' && typeof project?.closed === 'undefined' ?
            <>
                <TableCell align="right" style={{ visibility: "hidden" }}>
                    <LocalizedDate date={project.created} />
                </TableCell>
                <TableCell colSpan={2} align="center" sx={mutedStyle} style={{ visibility: "hidden" }}>
                    Project Ongoing
                </TableCell>
            </>
            :
            <>
                <TableCell align="right" style={{ visibility: "hidden" }}>
                    <LocalizedDate date={project.created} />
                </TableCell>
                <TableCell align="right" style={{ visibility: "hidden" }}>
                    {typeof project?.completed !== 'undefined' ? <LocalizedDate date={project.completed} /> : ""}
                </TableCell>
                <TableCell align="right" style={{ visibility: "hidden" }}>
                    {typeof project?.closed !== 'undefined' ? <LocalizedDate date={project.closed} /> : ""}
                </TableCell>
            </>
    };

    const isAddButtonDisabled = () => {
        return isDuplicateName || !isNameChanged || !isTypeChanged || !isRateChanged;
    };

    const handleAddProject = async e => {
        e.preventDefault();

        if (isAddButtonDisabled())
            return;

        await onAddProject({ name: projectName, type: projectType, rate: projectRate });
    };

    const handleCancelProjectChanges = e => {
        e.preventDefault();

        setProjectName(originalName ? originalName : '');
        setProjectType(originalType ? originalType : '');
        setProjectRate(originalRate ? originalRate : 0);

        setIsNameChanged(false);
        setIsTypeChanged(false);
        setIsRateChanged(false);
        setIsDuplicateName(false);
    };

    const generateStopStartButtons = projectId => {
        if (projectId && projectId === -1)
            return <>
                <IconButton
                    aria-label="cancel project"
                    size="small"
                    color="warning"
                    onClick={handleCancelAddNew}
                    title="Cancel adding this new project"
                >
                    <ClearIcon />
                </IconButton>
            </>;

        if (!isDuplicateName)
            if (isNameChanged || isTypeChanged || isRateChanged)
                return <>
                    <IconButton
                        aria-label="undo change to project"
                        size="small"
                        color="warning"
                        onClick={handleCancelProjectChanges}
                        title="Cancel all changes made to the project"
                    >
                        <UndoIcon />
                    </IconButton>
                </>;

        return <>
            <IconButton
                aria-label="complete project"
                size="small"
                color="warning"
                disabled={isNameChanged || isTypeChanged || isRateChanged}
                title="Change the status of the project to be completed"
                style={{ visibility: "hidden" }}
            >
                <StopCircleIcon />
            </IconButton>
        </>;
    };

    const handleCancelAddNew = e => {
        e.preventDefault();
        onCancelAdd();
    };

    const isUpdateButtonDisabled = () => {
        if (!isDuplicateName)
            if (isNameChanged || isTypeChanged || isRateChanged)
                return true;

        return false;
    }

    const handleUpdateProject = async e => {
        e.preventDefault();

        if (!isUpdateButtonDisabled())
            return;

        await onUpdateProject({ projectId: project?._id, name: projectName, type: projectType, rate: projectRate });

        handleCancelProjectChanges(e);
    };

    const handleRemoveProject = async e => {
        e.preventDefault();

        await onRemoveProject(project?._id);
    };

    const generateCancelDelteButtons = projectId => {
        if (projectId && projectId === -1)
            return <>
                <IconButton
                    aria-label="start project"
                    size="small"
                    color="success"
                    onClick={handleAddProject}
                    disabled={isAddButtonDisabled()}
                    title="Add this project to the client"
                >
                    <SaveIcon />
                </IconButton>
            </>;

        if (isUpdateButtonDisabled())
            return <>
                <IconButton
                    aria-label="update project"
                    size="small"
                    color="success"
                    onClick={handleUpdateProject}
                    title="Accept all changes made to this project"
                >
                    <SaveIcon />
                </IconButton>
            </>;

        return <>
            <IconButton
                aria-label="remove project"
                size="small"
                color="error"
                disabled={isNameChanged || isTypeChanged || isRateChanged}
                onClick={handleRemoveProject}
                title="Permanently remove this project from the client"
            >
                <DeleteIcon />
            </IconButton>
        </>;
    };

    const handleProjectNameTextboxChange = e => {
        setProjectName(e.target.value);
    };

    const generateProjectName = projectId => {
        if (projectId && projectId === -1)
            return <>
                <TextField
                    label={isDuplicateName ? "Project Name already exists" : "New Project Name"}
                    value={projectName}
                    onChange={handleProjectNameTextboxChange}
                    size="small"
                    fullWidth
                    style={textFieldStyle}
                    color={isDuplicateName ? "warning" : "primary"}
                    autoFocus
                />
            </>;

        return <>
            <TextField
                label={isDuplicateName ? "Project Name already exists" : "Project Name"}
                value={projectName}
                onChange={handleProjectNameTextboxChange}
                size="small"
                fullWidth
                style={textFieldStyle}
                color={isDuplicateName ? "warning" : "primary"}
            />
        </>;
    };

    const handleFrequencyChanged = frequency => {
        setProjectType(frequency);
    };

    const handleHourlyRateChanged = rate => {
        setProjectRate(rate);
    };

    return <>
        <TableRow key={project._id}>
            <TableCell component="td" scope="row" sx={{ minWidth: 250, pt: 2, pb: 2 }} >
                {generateProjectName(project._id)}
            </TableCell>
            <TableCell component="td" scope="row" sx={{ minWidth: 200, pt: 2, pb: 2 }}>
                <IncomeFrequency
                    frequency={projectType}
                    onChange={handleFrequencyChanged}
                />
            </TableCell>
            <TableCell component="td" scope="row" sx={{ pt: 2, pb: 2 }}>
                <CurrencyInput
                    value={projectRate}
                    size="small"
                    prefix={currency}
                    suffix="/hour"
                    useCurrencyPrefix={true}
                    sx={{ width: 160 }}
                    label={"Hourly Rate"}
                    onChange={handleHourlyRateChanged}
                />
            </TableCell>
            {generateDates(project?._id)}
            <TableCell align="right" component="td" scope="row" sx={{ pt: 2, pb: 2 }}>
                {generateStopStartButtons(project?._id)}
            </TableCell>
            <TableCell align="right" component="td" scope="row" sx={{ pt: 2, pb: 2 }}>
                {generateCancelDelteButtons(project?._id)}
            </TableCell>
        </TableRow>
    </>;
};

ProjectRow.propTypes = {
    project: PropTypes.shape({
        _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        type: PropTypes.string,
        rate: PropTypes.number,
        created: PropTypes.string,
        completed: PropTypes.string,
        closed: PropTypes.string,
    }).isRequired,
    allProjects: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string.isRequired
    })).isRequired,
    onAddProject: PropTypes.func.isRequired,
    onUpdateProject: PropTypes.func.isRequired,
    onRemoveProject: PropTypes.func.isRequired,
    onCancelAdd: PropTypes.func.isRequired
};

export default ProjectRow;
